import React, {PureComponent} from 'react'
import {graphql} from 'gatsby'
import '../assets/styles/app.scss'
import get from 'lodash/get'
import {withGuidelineContext} from '../contexts/GuidelineContext'
import { GuidelineLayout } from '../components/layouts'

class GuidelineTemplate extends PureComponent {
    componentDidMount() {
        const guideline = get(this.props, 'data.contentfulPageGuideline')

        this.props.guidelineContext.setGuideline(guideline)
    }

    render() {
        const { location } = this.props
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <GuidelineLayout location={location} sitemap={sitemap} />
        )
    }
}

export default withGuidelineContext(GuidelineTemplate)

export const pageQuery = graphql`
query GuidelineBySlug($slug: String!, $locale: String!) {
    contentfulPageGuideline(slug: { eq: $slug }, node_locale: { eq: $locale }) {
        title
        slug
        searchRedirectUrl
        publishDate
        serviceProvider {
            name
            slug
        }
        disclaimer {
            content {
                childContentfulRichText {
                    internal {
                        content
                    }
                    html
                }
            }
        }
        categories {
            name
            colour
        }
        guidelineType {
            guidelineType
        }
        authors {
            firstName
            lastName
        }
        owner {
            firstName
            lastName
        }
        editor {
            firstName
            lastName
        }
        summary {
            childMarkdownRemark {
                html
            }
        }
        metaDescription {
            metaDescription
        }
        richtext {
            childContentfulRichText {
                internal {
                    content
                }
                html
            }
        }
        references {
            childContentfulRichText {
                internal {
                    content
                }
                html
            }
        }
        reviewFrequency
        healthPathways {
            text
            url
        }
        relatedInformation {
            ... on ContentfulMedia {
                title
                media {
                    file {
                        url
                        details {
                            size
                        }
                    }
                    fields {
                        shortURL
                    }
                }
            }
            ... on ContentfulWidgetLink {
                text
                url
            }
            ... on ContentfulPageGuideline {
                title
                slug
            }
        }
        informationForFamilies {
            ... on ContentfulMedia {
                title
                media {
                    file {
                        url
                    }
                    fields {
                        shortURL
                    }
                }
            }
            ... on ContentfulWidgetLink {
                text
                url
            }
        }
        forms {
            ... on ContentfulMedia {
                title
                media {
                    file {
                        url
                    }
                    fields {
                        shortURL
                    }
                }
            }
            ... on ContentfulWidgetLink {
                text
                url
            }
        }
        externalTables {
            ... on ContentfulPageGuideline {
                title
                slug
            }
            ... on ContentfulWidgetLink {
                text
                url
            }
        }
        calculators {
            __typename
            name
            slug
        }
    }
    allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
        totalCount
        edges {
            node {
                contentful_id
                userSection
                title
                slug
                links {
                    __typename
                    ... on ContentfulPageCustom {
                        slug
                        title
                        contentful_id
                        userSection
                    }
                    ... on ContentfulPageDirectoryOfServices {
                        id
                        title
                    }
                    ... on ContentfulPageDonation {
                        slug
                        title
                        contentful_id
                    }
                    ... on ContentfulPageGeneric {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGroup {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGuideline {
                        title
                        slug
                    }
                    ... on ContentfulPageWidgets {
                        title
                        slug
                        userSection
                    }
                }
            }
        }
    }
}
`